import "./style.css";
import "maplibre-gl/dist/maplibre-gl.css";
import maplibregl from "maplibre-gl";
import { Map } from "maplibre-gl";

const map = new Map({
  container: "map",
  bounds: [
    [5.98865807458, 47.3024876979],
    [15.0169958839, 54.983104153],
  ],
  style: "https://tiles.openfreemap.org/styles/bright",
});

map.addControl(
  new maplibregl.NavigationControl({ showCompass: true }),
  "bottom-right",
);
map.addControl(new maplibregl.FullscreenControl());
